<template>
  <div class="overseasStudy">
    <div>
      <template v-if="language=='zh'">
        <myimage :url='url'></myimage>
      </template>
      <template v-if="language=='TW'">
        <myimage :url='urlTW'></myimage>
      </template>
      <template v-if="language=='En'">
        <myimage :url='urlEn'></myimage>
      </template>
    </div>
    <!-- 移动端 -->
    <div class="hidden-sm-and-up">

      <div class="content2-sm">
        <div class="admin-list-choose">
          <div v-for="(item,index) in lefttype" :key='index' @click="toleftindex(index)"  :class="leftindex===index?'leftactive':''">{{item}}</div>
        </div>
        <div class="title">
         <div class="english-yellow">{{$t('associatedegree.Advantages')}} </div>
         <div class="service-con">
          <div class="servicetop">{{$t('associatedegree.留学优势')}}</div>
            <div class="line2"></div>
          </div>
        </div>
        <div class="tabs">
          <div
              :class="tabsindex == index ? 'change' : ''"
              v-for="(item, index) in Associate"
              :key="index"
              @click="tabsclick(index,item.abroadId)"
          >{{item.subTitle}}
<!--            <template v-if="item.title.length<5">{{ item.title }}</template>-->
<!--            <template v-else>{{ item.title.slice(0,4) }}...</template>-->
          </div>
        </div>
        <div class="youshi">
          <div class="youshi-img">
            <el-image :src="baseUrl+AbroadDetils.image" fit="cover"></el-image>
          </div>
          <div>
            <div class="title2">{{AbroadDetils.title}}</div>
            <div class="text">
              {{AbroadDetils.content}}
            </div>
            <div class="btn" @click="contactus">{{$t('associatedegree.联系我们')}}</div>
          </div>
        </div>
      </div>
      <div class="bgc-sm">
        <div class="content3">
          <div class="title">
             <div class="english-yellow">{{$t('associatedegree.Study')}}</div>
             <div class="service-con">
               <div class="servicetop2">{{$t('associatedegree.选择适合你的留学计划')}}</div>
            <div class="line2"></div>
            </div>
          </div>
          <div class="list-sm">
            <template v-if="language=='zh'">
              <div class="list-abroadsm" @click="toapply"><img src="@/assets/phone/overseasStudy/plan1.png" alt=""></div>
              <div class="list-abroadsm" @click="toapply"><img src="@/assets/phone/overseasStudy/plan2.png" alt=""></div>
              <div class="list-abroadsm" @click="toapply"><img src="@/assets/phone/overseasStudy/plan4.png" alt=""></div>
            </template>
            <template v-if="language=='TW'">
              <div class="list-abroadsm" @click="toapply"><img src="@/assets/phone/overseasStudy/plan1TW.png" alt=""></div>
              <div class="list-abroadsm" @click="toapply"><img src="@/assets/phone/overseasStudy/plan2TW.png" alt=""></div>
              <div class="list-abroadsm" @click="toapply"><img src="@/assets/phone/overseasStudy/plan4TW.png" alt=""></div>
            </template>
            <template v-if="language=='En'">
              <div class="list-abroadsm" @click="toapply"><img src="@/assets/phone/overseasStudy/plan1En.png" alt="" style="height: 596px"></div>
              <div class="list-abroadsm" @click="toapply"><img src="@/assets/phone/overseasStudy/plan2En.png" alt="" style="height: 672px"></div>
              <div class="list-abroadsm" @click="toapply"><img src="@/assets/phone/overseasStudy/plan4En.png" alt="" style="height: 554px"></div>
            </template>
          </div>
        </div>
      </div>
      <div class="content2-sm">
        <div class="title" style="margin-bottom: 35px">
          <div class="english-yellow">{{$t('associatedegree.SERVICESYSTEM')}}</div>
          <div class="service-con">
            <div class="servicetop">
              {{$t('associatedegree.科学智能的服务体系')}}
            </div>

            <div class="line2"></div>
          </div>
        </div>
         <div class="service-plan-asso" >
            <div :class="language=='En'?'service-plan-assoheight2':'service-plan-assoheight'">
              <div class="service-plantitle-asso">{{$t('associatedegree.详细的留学规划')}}</div>
              <div class="planline-asso"></div>
              <div class="service-plancon-asso">
                 {{$t('associatedegree.基于企业内部庞大的数据库资料')}}
              </div>
            </div>        
            <div :class="language=='En'?'service-plan-assoheight2':'service-plan-assoheight'">
              <div class="service-plantitle-asso">{{$t('associatedegree.全方位的背景提升')}}</div>
              <div class="planline-asso"></div>
              <div class="service-plancon-asso">
                 {{$t('associatedegree.根据选校定位为学生匹配相关的背景提升项目和机会')}}
              </div>
            </div>              
            <div :class="language=='En'?'service-plan-assoheight2':'service-plan-assoheight'">
              <div class="service-plantitle-asso">{{$t('associatedegree.优质的文书材料')}}</div>
              <div class="planline-asso"></div>
              <div class="service-plancon-asso">
                {{$t('associatedegree.文书顾问与学生进行头脑风暴')}}
              </div>
            </div>            
            <div :class="language=='En'?'service-plan-assoheight2':'service-plan-assoheight'">
              <div class="service-plantitle-asso">{{$t('associatedegree.高效的笔试面试特训')}}</div>
              <div class="planline-asso"></div>
              <div class="service-plancon-asso">
                   {{$t('associatedegree.HK汇生会拥有丰富的本科')}}
              </div>
            </div>            
            <div :class="language=='En'?'service-plan-assoheight2':'service-plan-assoheight'">
              <div class="service-plantitle-asso"> {{$t('associatedegree.升学后的留学援助')}}</div>
              <div class="planline-asso"></div>
              <div class="service-plancon-asso">
                 {{$t('associatedegree.HK汇生会提供的学生公寓完美解决了学生在港留学的住宿问题')}}
              </div>
          </div>  
      </div>
      </div>
      <div class="content2-sm" >
        <div class="title">
          <div class="english-yellow">{{$t('associatedegree.AssociateDegreeProgressionPathway')}}</div>
          <div class="service-con">
            <div class="servicetop">
              {{$t('associatedegree.副学士升学途径')}}
            </div>
            <div class="line2"></div>
          </div>
        </div>
        <div class="zbeiimg2">
           <template v-if="language=='zh'">
             <img src="../../assets/phone/zb.png" alt="">
           </template>
          <template v-if="language=='TW'">
            <img src="../../assets/phone/zbTW.png" alt="">
          </template>
          <template v-if="language=='En'">
            <img src="../../assets/phone/zbEn.png" alt="">
          </template>
        </div>
       </div>
       <div class="content2-sm">
        <div class="title">
          <div class="english-yellow">{{$t('associatedegree.Introduction')}}</div>
          <div class="service-con">
            <div class="servicetop">
              {{$t('associatedegree.副学位课程介绍')}}
            </div>
            <div class="line2"></div>
          </div>
        </div>
        <div class="fxspng" >
            <template v-if="language=='zh'">
              <img src="../../assets/phone/asso/f1.png" alt="">
              <img src="../../assets/phone/asso/f2.png" alt="">
              <img src="../../assets/phone/asso/f3.png" alt="">
            </template>
          <template v-if="language=='TW'">
            <img src="../../assets/phone/asso/f1TW.png" alt="">
            <img src="../../assets/phone/asso/f2TW.png" alt="">
            <img src="../../assets/phone/asso/f3TW.png" alt="">
          </template>
          <template v-if="language=='En'">
            <img src="../../assets/phone/asso/f1En.png" alt=""  style="height: 490px">
            <img src="../../assets/phone/asso/f2En.png" alt=""  style="height: 490px">
            <img src="../../assets/phone/asso/f3En.png" alt="" style="height: 630px">
          </template>
        </div>
       </div>
       <div class="content2-sm">
        
        <div class="sqcon-main">
        <div class="title">
          <div class="english-yellow">{{$t('associatedegree.Application')}}</div>
          <div class="service-con">
            <div class="servicetop">
             {{$t('associatedegree.副学士申请条件')}} 
            </div>
            <div class="line2"></div>
          </div>
        </div>
            <div class="sqcondition">
                <div class="">
                    <div class="sqtiltle"> {{$t('associatedegree.内地高考成绩')}}</div>
                    <div class="sqbot">
                        <div> {{$t('associatedegree.内地高考成绩')}}</div>
                        <div>{{$t('associatedegree.达二本线或以上')}}</div>
                    </div>
                </div>
                <div class="">
                    <div class="sqtiltle"> {{$t('associatedegree.国际生成绩')}}</div>
                    <div class="sqbot">
                        <div>
                            <div> {{$t('associatedegree.HKDSE')}}\{{$t('associatedegree.SAT')}}\{{$t('associatedegree.LEVEL')}}\{{$t('associatedegree.IB')}}</div>
                            <div>{{$t('associatedegree.考试成绩')}}</div>
                        </div>
                        <div>
                            <div>{{$t('associatedegree.BETC')}}</div>
                            <div>{{$t('associatedegree.正规国际高中成绩单')}}</div>
                        </div>
                    </div>
                </div>
                <div class="">
                    <div class="sqtiltle">{{$t('associatedegree.面试成绩')}}</div>
                    <div class="sqbot">
                        <div>
                             {{$t('associatedegree.高考英语单科成绩达90分')}}
                        </div>
                        <div>
                            {{$t('associatedegree.满分150分制')}}
                        </div>
                    </div>
                </div>
                <div class="sqbtn">
                    <div class="sqbtn-title">
                        <div> {{$t('associatedegree.部分专业需要')}}</div>
                        <div> {{$t('associatedegree.具体分数要求')}}</div>
                    </div>
                    <div class="sqbtn-btn" @click="toapply">{{$t('associatedegree.点击咨询')}}</div>
                </div>
            </div>
        </div>
        
      </div>
      <div class="content2-sm">
        <div class="title">
          <div class="english-yellow">{{$t('associatedegree.Application')}}</div>
          <div class="service-con">
            <div class="servicetop">
             {{$t('associatedegree.副学士申请时间流程')}}
            </div>
            <div class="line2"></div>
          </div>
        </div>
        <div class="serve-process">
         <template v-if="language=='zh'">
           <img src="@/assets/phone/asso/sqlc.png" alt="">
         </template>
          <template v-if="language=='TW'">
            <img src="@/assets/phone/asso/sqlcTW.png" alt="">
          </template>
          <template v-if="language=='En'">
            <img src="@/assets/phone/asso/sqlcEn.png" alt="" style="height: 534px">
          </template>
        </div>
      </div>
      <div class="content2-sm">
      <div class="content2-sm">
        <div class="title">
          <div class="english-yellow">{{$t('associatedegree.Associate')}}</div>
          <div class="service-con">
            <div class="servicetop">{{$t('associatedegree.副学士录取案例')}}</div>
            <div class="line2"></div>
          </div>
        </div>
        <div class="success-offer-sm">
          <div class="" v-for="(item, index) in Admission" :key="index">
            <div class="success-offerleft-sm">
               <img :src="baseUrl+item.image" alt="">
            </div>
            <div class="success-offerright-sm">
              <div> {{$t('associatedegree.学生姓名')}}：{{item.studentName}}</div>
              <div> {{$t('associatedegree.申请类型')}}：{{item.applyType}}</div>
              <div> {{$t('associatedegree.学术成绩')}}：{{item.academicRecord}}</div>
              <div> {{$t('associatedegree.英语成绩')}}：{{item.englishResults}}</div>
              <div> {{$t('associatedegree.录取大学')}}：{{item.admissionSchool}}</div>
            </div>
          </div>
        </div>
        <div class="lqbtn"  @click="moreclick"> {{$t('associatedegree.查看更多')}}</div>
      </div> 
      </div>
    </div>
    <mydialog ref="dialog"></mydialog>
    <erweimaDialog ref="ewmdialog"></erweimaDialog>
  </div>
</template>

<script>
import myimage from '@/components/myimage'
import mydialog from '@/components/dialog'
import erweimaDialog from '@/components/erweimaDialog'
import {hongKongStudyAbroadDetils,hongKongStudyAbroadList} from '@/api/index.js'
export default {
  components: {
    myimage,mydialog,
    erweimaDialog
  },
  data() {
    return {
      url:require('../../assets/phone/tu1.png'),
      urlTW:require('../../assets/phone/tu1TW.png'),
      urlEn:require('../../assets/phone/tu1En.png'),
      src: "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
      list: ["优势一", "优势二", "优势三", "优势四", "优势五"],
      list2: [
        {
          title: "一站式申请",
          btn: "留学申请",
          color: "#4486e6",
        },
        {
          title: "高端申请",
          btn: "留学申请",
          color: "#F78A13",
        },
        {
          title: "DIY申请",
          btn: "留学申请",
          color: "#68D26E",
        },
      ],
      tabsindex: 0,
      baseUrl:'',
      Associate:[],
      AbroadDetils:{},
      Admission:[],//录取捷报
      // lefttype: ['副学士', '本科', '研究生'],
      leftindex: 0,
      language:'zh'
    };
  },
  computed:{
    lefttype(){
      return  [
        this.$t('overseasStudy.副学士'),
        this.$t('overseasStudy.本科'),
        this.$t('overseasStudy.研究生')
      ]
    },
  },
  mounted() {
    this.language=localStorage.getItem('language')
    this._hongKongStudyAbroadList(1,2)
    this._hongKongStudyAbroadList(4,6)
    this.baseUrl=this.$store.state.baseUrl
  },
  methods: {
    toleftindex(e) {
      this.leftindex = e

      if(e==0){//副学士
        this.$router.push({
          path: '/AssoStudy'
        });
      }
      if(e==1){//本科
        this.$router.push({
          path: '/undergraduateStudy'
        });
      }
      if(e==2){//研究生
        this.$router.push({
          path: '/gra-stuphone'
        });
      }
    },
    toapply(){
      this.$refs.ewmdialog.dialogVisibletwo=true
    },
    moreclick() {
      this.$router.push({
        path: '/Admission',
        query: {
          name: "Admission",
          twoindex: 0
        }
      });
    },
     _hongKongStudyAbroadList(category,type){
      hongKongStudyAbroadList({
        category:category,
        type:type,
      }).then(res=>{
        console.log(res)
          if(category==1){
            this.Associate=res.data
            this._hongKongStudyAbroadDetils(res.data[0].abroadId)
          }
           if(category==4){
          this.Admission=res.data.slice(0,4)
        }
      })
    },
    contactus(){
      this.$refs.ewmdialog.dialogVisibletwo=true
    },
     tabsclick(index,abroadId) {
      this.tabsindex = index;
      this._hongKongStudyAbroadDetils(abroadId)
      
    },

    _hongKongStudyAbroadDetils(abroadId){
      hongKongStudyAbroadDetils({
        abroadId:abroadId
      }).then(res=>{
        this.AbroadDetils=res.data
      })
    },
  },
};
</script>

<style lang="scss">
.overseasStudy {
  
  // 移动
  .content2-sm{
    .admin-list-choose{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    .admin-list-choose>div{
      width: 30%;
      height: 42PX;
      background: #f7f7f7;
      text-align: center;
      line-height: 42PX;
      font-size: 12PX;
      margin-top: 20PX;
    }
    .sqcon-main{
        width: 100vw;
        background: #f7f7f7;
        padding-bottom: 20PX;
        .sqcondition{
        width: 335PX;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        .sqbtn{
            background: #FCA648;
            display: flex;
            flex-direction: column;
            align-items:center ;
            justify-content: space-between;
            padding: 22PX 0;
            .sqbtn-title{
                font-size: 16PX;
                color: #fff;
                font-weight: bold;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .sqbtn-btn{
                font-size: 14PX;
                background: #fff;
                color: #FE992B;
                width: 119PX;
                height: 36PX;
                border-radius: 24PX;
                line-height: 36PX;
            }
        }
        >div{
            width: 159PX;
            height: 217PX;
            margin-top: 16PX;
            border-radius: 12PX;
            .sqtiltle{
                color: #fff;
                font-size: 16PX;
                font-weight: bold;
                height: 64PX;
                //line-height: 64PX;
                background: #FE992B;
                border-radius: 12PX;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .sqbot{
                height: 141PX;
                background: #fff;
                box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
                border-radius: 12PX;
                font-size: 12PX;
                margin-top: 12PX;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }
    }
    }
    
    .fxspng img{
        width: 335PX;
        height: 420PX;
        margin-top: 16PX;
    }
    .zbeiimg2{
      overflow-x: scroll;
    }
    .zbeiimg2 img{
        //width: 335PX;
        height: 268PX;
    }
     .service-plan-asso {
        width: 335PX;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 16PX;
      }
.planline-asso {
  width: 50%;
  height: 10PX;
  background: linear-gradient(180deg, #FE992B 0%, rgba(254, 153, 43, 0.3) 100%);
  border-radius: 5PX;
  position: absolute;
  top: 40PX;
  text-align: center;
  left: calc(50% - 25%);
}
    .service-plan-assoheight{
      height: 250PX!important;
    }
    .service-plan-assoheight2{
      height: 350PX!important;
    }
.service-plan-asso > div {
  width: 162PX;
  padding: 0 19PX;
  background: #FEF8E9;
  border-radius: 12PX;
  position: relative;
  margin-bottom: 10PX;
}

.service-plantitle-asso {
  margin: 24PX auto 24PX auto;
  font-size: 16PX;
  font-weight: bold;
  position: relative;
  z-index: 1;
}

.service-plancon-asso {
  text-align: left;
  font-size: 12PX;
}
.service-plan-asso > div:hover{
  background: linear-gradient(134deg, #FCA648 0%, #FE992B 100%);
  cursor: pointer;
  color: #FFF !important;
  transition: all 0.5s;
}
    width: 100vw;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    
.leftactive {
  background: rgba(254, 153, 43, 0.2)!important;
  //font-weight: bold;
}
    >div{
      width: 335PX;
      margin: 0 auto;
    }
    .title {
      position: relative;
      height: 68PX;
      margin-top: 20PX;

      .english-yellow {
        color: #fe992b;
        font-size: 20PX;
        opacity: 0.3;
      }

      .service-con {
        width: 100%;
        position: absolute;
        top: 0;
      }

      .line2 {
        width: 69PX;
        height: 3PX;
        border-radius: 3PX;
        background-color: #fe992b;
        margin: 0 auto;
      }

      .servicetop {
        // width: 160px;
        font-size: 20PX;
        color: #333;
        font-weight: bold;
        padding: 10PX 0;
        // border-bottom: 6PX solid #fe992b;
        border-radius: 3PX;
        margin: 0 auto;
      }

      .servicetop2 {
        width: 320PX;
        font-size: 32PX;
        color: #333;
        font-weight: bold;
        padding: 10PX 0;
        border-bottom: 6PX solid #fe992b;
        border-radius: 3PX;
        margin: 0 auto;
      }
      
    }
    .tabs {
      border-bottom: 3PX solid rgba($color: #fe992b, $alpha: 0.3);
      display: flex;
      justify-content: center;
      margin-bottom: 36PX;

      > div {
        //width:70PX;
        padding: 0 5PX;
        height: 24PX;
        border-radius: 8PX 8PX 0px 0px;
        line-height: 24PX;
        text-align: center;
        color: #333;
        font-size: 12PX;
        // transition: all 0.5s;
      }

      .change {
        background-color: #fe992b;
        color: #fff;
        transition: all 0.5s;
      }
    }
    
    .youshi {
      display: flex;
      flex-direction: column;
      text-align: left;
      margin-bottom: 66PX;

      .youshi-img {
        margin-right: 36PX;
        width:335PX!important;
          //height: 360PX;
        > img {
          width:335PX!important;
          height: 352PX;
        }
      }

      div:nth-child(2) {
        position: relative;

        .btn {
          position: absolute;
          width: 150PX;
          height: 48PX;
          border-radius: 35PX;
          line-height: 48PX;
          text-align: center;
          background-color: #fe992b;
          color: #fff;
          font-size: 16PX;
          font-weight: 600;
          bottom: -50PX;
          left: calc(45vw - 75PX);
        }

        .text {
          width: 335PX;
          font-size: 14PX;
          line-height: 28PX;
        }

        .title2 {
          color: #333;
          font-size: 16PX;
          font-weight: 600;
          margin: 10PX 0;
          font-weight: bold;
        }
      }
    }
    .serve-system{
      img{
        width: 100%;
        height: 799PX;
      }
    }
    .serve-process{
      width: 335PX;
      overflow-x: scroll;
      margin: 30PX auto 16PX auto;
      img{
        height: 478PX;
        width: 900PX;
      }
    }
    .Expert-team {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;

      > div {
        width: 128PX;
        margin-bottom: 30PX;

        > div:nth-child(1) {
          > img {
            width:  128PX;
            height:  128PX;
            border-radius: 50%;
          }
        }

        > div:nth-child(2) {
          font-size: 16PX;
          font-weight: 600;
          margin-top: 26px;
        }

        > div:nth-child(3) {
          font-size: 14PX;
          margin-top: 10px;
        }
      }
    }
    
      .success-offer-sm {
        margin-top: 25PX;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
      }

      .success-offer-sm > div {
        padding: 20PX 16PX 40PX 16PX;
        background: #fff;
        width: 335PX;
        //height: 247PX;
        display: flex;
        flex-direction: row;
        align-items: center;
        // padding-bottom: 80px;
        margin-bottom: 16PX;
        box-shadow: 0px 3PX 6PX 1PX rgba(0, 0, 0, 0.16);
      }

      .success-offerleft-sm {
        background: #333;
        width: 138PX;
        height: 207PX;
        margin-right: 15PX;
      }
      .success-offerleft-sm img{
        width: 138PX;
        height: 207PX;
      }
      .success-offerright-sm {
        width: 50%;
        line-height: 26PX;
        height: 207PX;
        text-align: left;
        font-size: 14PX;
        white-space: pre-line;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #333;
      }
      .lqbtn {
            width: 150PX;
            height: 48PX;
            background-color: #FE992B;
            border-radius: 24PX;
            color: #fff;
            font-size: 16PX;
            line-height: 48PX;
            text-align: center;
            margin: 0 auto;
            margin-top: 50px;
            margin-bottom: 20PX;
          }
    .NEWS-btn {
      width: 150PX;
      height: 48PX;
      border-radius: 35PX;
      line-height:48PX;
      text-align: center;
      background-color: #fe992b;
      color: #fff;
      font-size: 16PX;
      font-weight: 600;
      margin: 0 auto;
      // margin-top: 50PX;
      margin-bottom: 20PX;
    }

    .NEWS-sm {
      display: flex;
      flex-direction: column;
      .NEWS-img{
        width: 335PX;
        height: 224PX;
      }
      .NEWS-list {
        > div {
          font-size: 16PX;
          margin: 22PX 0;

          > span {
            color: #333;
          }

          img {
            width: 24PX;
            height: 24PX;
            vertical-align: middle;
            margin-right: 10PX;
          }
        }
      }

      .img {
        margin-right: 50px;

        img {
          width: 684px;
          height: 500px;
        }
      }
    }

  }
   .bgc-sm {
    width: 100%;
    background-color: #f7f7f7;

    .content3 {
      width:335PX;
      margin: 0 auto;
      padding-top: 1PX;

      .btn {
        width: 234PX;
        height: 70PX;
        border-radius: 35PX;
        line-height: 70PX;
        text-align: center;
        background-color: #fe992b;
        color: #fff;
        font-size: 20PX;
        font-weight: 600;
        bottom: 5PX;
        margin: 0 auto;
      }

      .famous-bot-hk{
        padding: 24PX 0;
        img{
          width: 335PX;
          height: 446PX;
        }
      }
     .list-sm {
        display: flex;
        flex-direction: column;
        justify-content: center;
        //padding-bottom: 20PX;
        // padding-top: 20PX;
       .list-abroadsm img{
          width: 100%;
          height: 522PX;
          margin-top: 20PX;
        }
      }

      .title {
        position: relative;
        height: 68PX;
        margin-top: 20PX;

        .english-yellow {
          color: #fe992b;
          font-size: 20PX;
          opacity: 0.3;
        }

        .service-con {
          width: 100%;
          position: absolute;
          top: 0;
        }

        .line2 {
          width: 69PX;
          height: 3PX;
          border-radius: 3PX;
          background-color: #fe992b;
          margin: 0 auto;
        }

        .servicetop2 {
          // width: 320px;
          font-size: 20PX;
          color: #333;
          font-weight: bold;
          padding: 10PX 0;
          // border-bottom: 6px solid #fe992b;
          border-radius: 3PX;
          margin: 0 auto;
        }
      }
    }
  }
}
  .zhunbei-sm {
      padding-top: 40PX;
      .zhunbeimsg{
        .zhunbeione img{
          width:335PX;
          height: 500PX;
        }
        .zhunbeitwo{
          position: relative;
            img{
          width: 335PX;
          height: 571PX;
        }
        .zhunbeitwo-text{
          position: absolute;
          top: 140PX;
          left: calc(30PX + 5vw);
          .zhunbeitwo-text-top{
            font-size: 18PX;
            font-weight: bold;
            margin-bottom: 16PX;
          }
        }
        }
       .zhunbeithree{
         position: relative;
         margin-top: 15PX;
          img{
          width: 335PX;
          height: 329PX;
        }
       }
      }
      .title {
      position: relative;
      height: 68PX;

      .english-yellow {
        color: #fe992b;
        font-size: 20PX;
        opacity: 0.3;
      }

      .service-con {
        width: 100%;
        position: absolute;
        top: 0;
      }

      .line2 {
        width: 69PX;
        height: 3PX;
        border-radius: 3PX;
        background-color: #fe992b;
        margin: 0 auto;
      }

      .servicetop {
        // width: 160px;
        font-size: 20PX;
        color: #333;
        font-weight: bold;
        padding: 10PX 0;
        // border-bottom: 6PX solid #fe992b;
        border-radius: 3PX;
        margin: 0 auto;
      }

      .servicetop2 {
        width: 320PX;
        font-size: 32PX;
        color: #333;
        font-weight: bold;
        padding: 10PX 0;
        border-bottom: 6PX solid #fe992b;
        border-radius: 3PX;
        margin: 0 auto;
      }
    }
        .accordion {
          padding: 30PX;

          .el-collapse-item__content {
            color: #666;
            font-size: 16PX;
          }

          .title2 {
            font-size: 18PX;
            font-weight: 600;
          }

          .el-collapse-item__header {
            border: none;
            margin-bottom: 20PX;
          }

          .el-collapse {
            border: none;
          }

          .el-collapse-item__arrow {
            color: #fe992b;
            font-size: 16PX;
          }

          .el-collapse-item__wrap {
            border: none;
          }
        }
      .card {
          text-align: left;
          width:320PX;
          min-height: 518PX;
          box-shadow: 0px 3PX 6PX 1PX rgba(0, 0, 0, 0.16);
          margin: 0 auto;

          > div:nth-child(1) {
            width: 320PX;
            height: 110PX;
            background-image: url(../../assets/image/846.png);
            font-size: 24PX;
            color: #fff;
            padding: 22PX 30PX 0 30PX;

            > div {
              line-height: 32PX;
            }
          }
        }
      
      
    } 
</style>